.about {
  max-width: 1000px;
  margin: 0px auto;
}
.aboutHeading{
  display: flex;
  align-items: center;
  font-size: 32px;
}
.aboutHeading::after {
  content: "";
  display: block;
  height: 1px;
  width: 300px;
  background-color: rgb(136, 146, 176);
  position: relative;
  margin-top: 12px;
  margin-left: 20px;
}
.about-child-child {
  display: flex;
  align-items: flex-start;
}
.about__Skills {
  display: grid;
  grid-template-columns: repeat(3, minmax(140px, 200px));
  overflow: hidden;
  padding: 0px;
  margin: 20px 0px 0px;
  list-style: none;
}
.about__SkillName {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-size: 13px;
  color: rgb(136, 146, 176);
}
.about__SkillName::before {
  content: "▹";
  position: absolute;
  left: 0px;
  color: rgb(100, 255, 218);
  font-size: 14px;
  line-height: 12px;
}
p{
  margin: 0px 0px 10px;
  display: inline-flex;
  color: rgb(136, 146, 176);
  font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
  font-size: 18px;
}