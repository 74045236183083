@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Teko:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

*{
  margin:1px;
  padding: 2px;
  background-color: #1A2238;
}



.workContainer {
  text-align: center;
  max-width: 1000px;
  margin: 50px auto 100px;
}
.workTitle{
  display: flex;
  align-items: center;
  font-size: 32px;
}
.workTitle::after {
  content: "";
  display: block;
  height: 1px;
  width: 300px;
  background-color: rgb(136, 146, 176);
  position: relative;
  margin-top: 12px;
  margin-left: 20px;
}

