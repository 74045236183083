.hero{
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0px auto;
  padding-top: 5%;
  padding-bottom: 5%;
}
h1{
  font-size: 80px;
  line-height: 1.1;
  margin: 0px;
  font-weight: 600;
  color: rgb(204, 214, 246);
}
h2{
  font-size: 50px;
  line-height: 1.1;
  color: rgb(136, 146, 176);
  font-weight: 600;
  margin: 0px 0px 1px;
}
h3{
  width: 100%;
  color: rgb(136, 146, 176);
  line-height: 1.3;
  font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
  font-size: 30px;
  padding-top:20px;
}
p{
    margin: 0px 0px 10px;
    display: flex;
    color: rgb(136, 146, 176);
    line-height: 1.3;
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
    font-size: 20px;
}

h5{
  color: rgb(111, 192, 36);
  font-size: 16px;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-weight: normal;
  margin: 0px 0px 20px 3px;
}
.email-button{
    display: flex;
    width:15%;
    color:  rgb(111, 192, 36);
    background-color: transparent;
    font-size: 20px;
    margin-left: -2px;
    font-family: "Teko","Montserrat";
    line-height: 1;
    cursor: pointer;
    margin-top: 0px;
    border-width: 1px;
    border-style: solid;
    border-color:  rgb(111, 192, 36);
    border-image: initial;
    border-radius: 1px;
    padding: 0.75rem 0.75rem;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.email-button:hover{
  background-color:rgb(136, 146, 176);
}