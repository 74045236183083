@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Teko:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Teko:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo{
  cursor: pointer;
  margin-right: auto;
  justify-content: space-around;
  color:  rgb(111, 192, 36);
  display: inline-block;
}
.navbarHeaderContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: rgb(204, 214, 246);
}
.navContainer {
  display: flex;
}
.navContainerUnorderList {
  display: flex;
  align-items: center;
}
.navContainerLink,li,a{
  list-style: none;
  margin: 0px 10px;
  font-family: "Teko","Montserrat";
  font-weight: 500;
  font-size: 20px;
  color: rgb(204, 214, 246);
  text-decoration: none;
}

.navContainerLink:hover{
  color: rgb(111, 192, 36);
}
.resumeButtonLink {
    display: block;
    color:  rgb(111, 192, 36);
    background-color: transparent;
    font-size: 20px;
    font-family: "Teko","Montserrat";
    line-height: 1;
    cursor: pointer;
    margin-top: 0px;
    border-width: 1px;
    border-style: solid;
    border-color:  rgb(111, 192, 36);
    border-image: none;
    border-image: initial;
    border-radius: 1px;
    padding: 0.75rem 1rem;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.resumeButtonLink:hover{
  background-color: rgb(136, 146, 176);
}


.side-navbar{
    width: 40px;
    position: fixed;
    bottom: 0px;
    left: 40px;
    right: auto;
    color: rgb(168, 178, 209);
}
.socail-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.icon {
  padding: 10px;
}
.socail-list::after {
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  background-color: rgb(168, 178, 209);
  margin: 0px auto;
}
.side-email-link{
    width: 40px;
    position: fixed;
    bottom: 0px;
    left: auto;
    right: 40px;
    color: rgb(168, 178, 209);
}
.email-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.email__Link {
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-size: 12px;
  letter-spacing: 0.1em;
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  margin: 20px auto;
  padding: 10px;
}
.side-email-link::after {
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  background-color: rgb(168, 178, 209);
  margin: 0px auto;
}
.hamburgerMenuNavContainer {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  text-transform: none;
  color: inherit;
  background-color: transparent;
  display: none;
  overflow: visible;
  margin: 0px -12px 0px 0px;
  padding: 15px;
  border-width: 0px;
  border-style: none;
  border-style: initial;
  border-color: currentColor;
  border-color: initial;
  border-image: none;
  border-image: initial;
}
.hamburgerMenuNav {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 24px;
}
.hamburgerMenuNav {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 24px;
}
.hamburgerMenuNavChild {
  background-color: rgb(111, 192, 36);
    position: absolute;
    width: 30px;
    height: 2px;
    top: 50%;
    left: 0px;
    right: 0px;
    transition-duration: 0.22s;
    transition-property: transform;
    transition-delay: 0s;
    transform: rotate(0deg);
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    border-radius: 3px;
}
.hero{
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0px auto;
  padding-top: 5%;
  padding-bottom: 5%;
}
h1{
  font-size: 80px;
  line-height: 1.1;
  margin: 0px;
  font-weight: 600;
  color: rgb(204, 214, 246);
}
h2{
  font-size: 50px;
  line-height: 1.1;
  color: rgb(136, 146, 176);
  font-weight: 600;
  margin: 0px 0px 1px;
}
h3{
  width: 100%;
  color: rgb(136, 146, 176);
  line-height: 1.3;
  font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
  font-size: 30px;
  padding-top:20px;
}
p{
    margin: 0px 0px 10px;
    display: flex;
    color: rgb(136, 146, 176);
    line-height: 1.3;
    font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
    font-size: 20px;
}

h5{
  color: rgb(111, 192, 36);
  font-size: 16px;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-weight: normal;
  margin: 0px 0px 20px 3px;
}
.email-button{
    display: flex;
    width:15%;
    color:  rgb(111, 192, 36);
    background-color: transparent;
    font-size: 20px;
    margin-left: -2px;
    font-family: "Teko","Montserrat";
    line-height: 1;
    cursor: pointer;
    margin-top: 0px;
    border-width: 1px;
    border-style: solid;
    border-color:  rgb(111, 192, 36);
    border-image: none;
    border-image: initial;
    border-radius: 1px;
    padding: 0.75rem 0.75rem;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.email-button:hover{
  background-color:rgb(136, 146, 176);
}
.about {
  max-width: 1000px;
  margin: 0px auto;
}
.aboutHeading{
  display: flex;
  align-items: center;
  font-size: 32px;
}
.aboutHeading::after {
  content: "";
  display: block;
  height: 1px;
  width: 300px;
  background-color: rgb(136, 146, 176);
  position: relative;
  margin-top: 12px;
  margin-left: 20px;
}
.about-child-child {
  display: flex;
  align-items: flex-start;
}
.about__Skills {
  display: grid;
  grid-template-columns: repeat(3, minmax(140px, 200px));
  overflow: hidden;
  padding: 0px;
  margin: 20px 0px 0px;
  list-style: none;
}
.about__SkillName {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-size: 13px;
  color: rgb(136, 146, 176);
}
.about__SkillName::before {
  content: "▹";
  position: absolute;
  left: 0px;
  color: rgb(100, 255, 218);
  font-size: 14px;
  line-height: 12px;
}
p{
  margin: 0px 0px 10px;
  display: inline-flex;
  color: rgb(136, 146, 176);
  font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
  font-size: 18px;
}
.contactContainer {
  text-align: center;
  max-width: 1000px;
  margin: 50px auto 100px;
}
.contactTitle{
  display: flex;
  align-items: center;
  font-size: 32px;
}
.contactTitle::after {
  content: "";
  display: block;
  height: 1px;
  width: 300px;
  background-color: rgb(136, 146, 176);
  position: relative;
  margin-top: 12px;
  margin-left: 20px;
}

.contactTitleChild {
    display: flex;
    color: rgb(100, 255, 218);
    font-size: 16px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-weight: normal;
    justify-content: center;
    margin: 20px 0px 1px;
  }

.contactContainer a {
  margin:15px;
  display: inline-block;
  cursor: pointer;
  color: rgb(100, 255, 218);
  text-decoration: none;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.contactEmailButtonLink{
  display: flex;
  width:15%;
  color:  rgb(111, 192, 36);
  background-color: transparent;
  font-size: 20px;
  margin-left: -2px;
  font-family: "Teko","Montserrat";
  line-height: 1;
  cursor: pointer;
  margin-top: 0px;
  border-width: 1px;
  border-style: solid;
  border-color:  rgb(111, 192, 36);
  border-image: none;
  border-image: initial;
  border-radius: 1px;
  padding: 0.75rem 0.75rem;
  text-decoration: none;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.contactEmailButtonLink:hover{
background-color:rgb(136, 146, 176);
}
.footerContainer {
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}
.footerStyle {
  color: rgb(168, 178, 209);
  display: flex;
  align-items: center;
}

.footerStyleList{
  color: rgb(168, 178, 209);
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.icon {
  padding: 10px;
}
.footerText {
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  color: rgb(204, 214, 246);
  display: flex;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-size: 12px;
  line-height: 1;
}


.experience {
  text-align: center;
  max-width: 1000px;
  margin: 50px auto 100px;
}
.experienceTitle {
  display: flex;
  align-items: center;
  font-size: 32px;
}
.experienceTitle::after {
  content: "";
  display: block;
  height: 1px;
  width: 300px;
  background-color:rgb(136, 146, 176);
  position: relative;
  margin-top: 12px;
  margin-left: 20px;
}
.experienceTitle::before {
  counter-increment: section 2;
 
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-weight: normal;
  color: rgb(100, 255, 218);
  font-size: 20px;
  position: relative;
  bottom: 4px;
}

.jobsList {
  display: flex;
  align-items: flex-start;
}

.jobTabList {
  display: block;
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.tablistchild {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  height: 42px;
  text-align: left;
  white-space: nowrap;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-size: 13px;
  color: rgb(100, 255, 218);
  text-decoration: none;
  padding: 0px 20px 2px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  border-left: 2px solid rgb(48, 60, 85);
}

.jobs__StyledHighlight {
  display: block;
  width: 2px;
  height: 42px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  transform: translateY(0px);
  background: rgb(100, 255, 218);
  border-radius: 3px;
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}


.jobContent {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 12px;
  padding-left: 60px;
}
.jobTitle {
  color: rgb(204, 214, 246);
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 5px;
}
.jobCompany {
  color: rgb(100, 255, 218);
}
.jobContent a {
  display: inline-block;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  position: relative;
  cursor: pointer;
  color: rgb(100, 255, 218);
  text-decoration: none;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.jobContent a::after {
  content: "";
  display: block;
  width: 0px;
  height: 1px;
  position: relative;
  bottom: 0.37em;
  background-color: rgb(100, 255, 218);
  opacity: 0.5;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.jobDetails {
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.05em;
  color: rgb(168, 178, 209);
  margin-bottom: 30px;
}
.jobContent ul li::before {
  position: absolute;
  left: 0px;
  color: rgb(100, 255, 218);
}







*{
  margin:1px;
  padding: 2px;
  background-color: #1A2238;
}



.workContainer {
  text-align: center;
  max-width: 1000px;
  margin: 50px auto 100px;
}
.workTitle{
  display: flex;
  align-items: center;
  font-size: 32px;
}
.workTitle::after {
  content: "";
  display: block;
  height: 1px;
  width: 300px;
  background-color: rgb(136, 146, 176);
  position: relative;
  margin-top: 12px;
  margin-left: 20px;
}


