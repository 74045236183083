.contactContainer {
  text-align: center;
  max-width: 1000px;
  margin: 50px auto 100px;
}
.contactTitle{
  display: flex;
  align-items: center;
  font-size: 32px;
}
.contactTitle::after {
  content: "";
  display: block;
  height: 1px;
  width: 300px;
  background-color: rgb(136, 146, 176);
  position: relative;
  margin-top: 12px;
  margin-left: 20px;
}

.contactTitleChild {
    display: flex;
    color: rgb(100, 255, 218);
    font-size: 16px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-weight: normal;
    justify-content: center;
    margin: 20px 0px 1px;
  }

.contactContainer a {
  margin:15px;
  display: inline-block;
  cursor: pointer;
  color: rgb(100, 255, 218);
  text-decoration: none;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.contactEmailButtonLink{
  display: flex;
  width:15%;
  color:  rgb(111, 192, 36);
  background-color: transparent;
  font-size: 20px;
  margin-left: -2px;
  font-family: "Teko","Montserrat";
  line-height: 1;
  cursor: pointer;
  margin-top: 0px;
  border-width: 1px;
  border-style: solid;
  border-color:  rgb(111, 192, 36);
  border-image: initial;
  border-radius: 1px;
  padding: 0.75rem 0.75rem;
  text-decoration: none;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.contactEmailButtonLink:hover{
background-color:rgb(136, 146, 176);
}
.footerContainer {
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}
.footerStyle {
  color: rgb(168, 178, 209);
  display: flex;
  align-items: center;
}

.footerStyleList{
  color: rgb(168, 178, 209);
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.icon {
  padding: 10px;
}
.footerText {
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  color: rgb(204, 214, 246);
  display: flex;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-size: 12px;
  line-height: 1;
}
